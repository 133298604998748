import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from 'ngx-cookie-service';

import { Token } from './../_models/token';

@Injectable({
	providedIn: "root"
})
export class BaseService {

	currentUser: any;
	token: string;
	decodedToken: Token;
	href: string[];
	helper = new JwtHelperService();

	constructor(private cookieService: CookieService) { }

	public getCurrentUserToken() {
		this.currentUser = JSON.parse(this.cookieService.get('currentUser'));
		return this.currentUser.token;
	}

	public decodeToken(token: string) {
		return this.helper.decodeToken(token);
	}

	public getDecodedToken() {
		return this.decodeToken(this.getCurrentUserToken());
	}

	public getUserId(): number {
		const token = this.getCurrentUserToken();
		const decoded: Token = this.decodeToken(token);
		
		return decoded.id;
	}

	public getUserToken(): Token {
		const token = this.getCurrentUserToken();
		const decoded: Token = this.decodeToken(token);
		return decoded;
	}

	public get authHeader(): string {
		this.token = this.getCurrentUserToken();
		this.decodedToken = this.decodeToken(this.token);

		return `Bearer ${this.token}`;
	}

	public setAuthHeader(): HttpHeaders {

		return new HttpHeaders({
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Credentials": "true",
			"Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
			"Access-Control-Max-Age": "3600",
			"Access-Control-Allow-Headers": "X-Requested-With, Content-Type, Authorization, Origin, Accept, Access-Control-Request-Method, Access-Control-Request-Headers"
		})
			.set(
				"Authorization",
				this.authHeader
			);
	}

	public isTokenExpired(token: string) {
		const isExpired = this.helper.isTokenExpired(token);

		return isExpired;
	}

	public updateToken(username: string, token: string) {
		this.cookieService.delete("currentUser", "/");
		this.cookieService.set('currentUser', JSON.stringify({ username: username, token: token }), 0.25, "/", null!, false, 'Strict');
	}

	public parseUrl(value: string) {
		this.href = value.split("/");
		return this.href;
	}

	public removeParamFromUrl(newUrl: string) {
		window.history.pushState("", "ControlPanel", "/" + newUrl);
	}

}